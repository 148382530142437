$( document ).ready( function () {
	let _page = 1;

	function loadUGC( page ) {
		$.ajax( {
			url: 'https://www.juicer.io/api/feeds/psychosway?per=18&page=' + page,
			dataType: 'json',
			method: 'GET',
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				if ( data.posts.items.length > 0 ) {
					let html = '';
					for ( const item of data.posts.items ) {
						html += '<article style="display: none;">';
						html += '<a href="' + item.full_url + '" target="_blank">';
						html += '<img src="' + item.image.replace( 'hqdefault', 'mqdefault' ) + '" alt="' + item.external_id + '" />';
						html += '</a>';
						html += '</article>';
					}
					$( '#ugc' ).append( html );
					$( '#ugc article' ).fadeIn();
				} else {
					$( '#videos .btn' ).fadeOut();
				}
			},
			error: function () {
				alert( 'An error has occurred loading UGC!' );
			}
		} );
	}

	loadUGC( 1 );

	$( '#videos .btn' ).on( 'click', function ( e ) {
		e.preventDefault();
		_page = _page + 1;

		loadUGC( _page );
	} );

	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();

		const data = $( this ).serialize();

		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).replaceWith( '<div class="thanks">Download Here: <a href="https://masn.lnk.to/PsychoRemixDownload" target="_blank">masn.lnk.to/PsychoRemixDownload</a></div>' );
				$( '#videos' ).fadeIn();
			},
			error: function ( err ) {

				alert( 'An error has occurred!' );
			}
		} );
	} );
} );